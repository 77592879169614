import {pusher} from './pusher';
import {getMainDefinition} from 'apollo-utilities';
import {ApolloLink, Observable} from 'apollo-link';
import {setAuthResponseForChannel} from './pusher-auth';

// Inspired by https://github.com/rmosolgo/graphql-ruby/blob/master/javascript_client/src/subscriptions/PusherLink.ts
class PusherLink extends ApolloLink {
    constructor(options) {
        super();

        this.pusher = options.pusher;
        this.socketId = undefined;

        if (this.pusher) {
            this.pusher.connection.bind('connected', () => (this.socketId = this.pusher.connection.socket_id));
            this.pusher.connection.bind('disconnected', () => (this.socketId = undefined));
        }
    }

    request(operation, forward) {
        const definition = getMainDefinition(operation.query);

        if (definition.kind !== 'OperationDefinition' || definition.operation !== 'subscription') {
            return forward(operation);
        }

        const subscribeObservable = new Observable(_observer => {
            //
        });

        // Capture the super method
        const prevSubscribe = subscribeObservable.subscribe.bind(subscribeObservable);

        // Override subscribe to return an `unsubscribe` object, see
        // https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L182-L212
        subscribeObservable.subscribe = (observerOrNext, onError, onComplete) => {
            prevSubscribe(observerOrNext, onError, onComplete);

            const observer = getObserver(observerOrNext, onError, onComplete);

            let subscriptionChannel;

            if (this.socketId) {
                operation.setContext({
                    headers: {
                        'chief-socket-id': this.socketId,
                    },
                });
            }

            forward(operation).subscribe({
                next: data => {
                    // If the operation has the subscription channel, it's a subscription
                    subscriptionChannel = this.getChannelFromResponse(data);

                    // No subscription found in the response, pipe data through
                    if (!subscriptionChannel) {
                        observer.next(data);
                        observer.complete();

                        return;
                    }

                    if (this.socketId) {
                        setAuthResponseForChannel(subscriptionChannel, this.getChannelAuthFromResponse(data));
                    }

                    this.subscribeToChannel(subscriptionChannel, observer);
                },
            });

            // Return an object that will unsubscribe_if the query was a subscription
            return {
                closed: false,
                unsubscribe: () => {
                    subscriptionChannel && this.unsubscribeFromChannel(subscriptionChannel);
                },
            };
        };

        return subscribeObservable;
    }

    subscribeToChannel(subscriptionChannel, observer) {
        // If there is no pusher client that means WebSockets are disabled
        if (this.pusher === null) {
            observer.complete();

            return;
        }

        this.pusher.subscribe(subscriptionChannel).bind('lighthouse-subscription', payload => {
            if (!payload.more) {
                this.pusher.unsubscribe(subscriptionChannel);

                observer.complete();
            }

            const result = payload.result;

            if (result) {
                observer.next(result);
            }
        });
    }

    unsubscribeFromChannel(subscriptionChannel) {
        // If there is no pusher client that means WebSockets are disabled
        if (this.pusher === null) {
            return;
        }

        this.pusher.unsubscribe(subscriptionChannel);
    }

    getChannelFromResponse(response) {
        if (!!response.extensions && !!response.extensions.lighthouse_subscriptions && !!response.extensions.lighthouse_subscriptions.channel) {
            return response.extensions.lighthouse_subscriptions.channel;
        }

        return null;
    }

    getChannelAuthFromResponse(response) {
        if (!!response.extensions && !!response.extensions.chief_socket && !!response.extensions.chief_socket.auth) {
            return response.extensions.chief_socket.auth;
        }

        return null;
    }
}

// Turn `subscribe` arguments into an observer-like thing, see getObserver
// https://github.com/apollographql/subscriptions-transport-ws/blob/master/src/client.ts#L329-L343
function getObserver(observerOrNext, onError, onComplete) {
    if (typeof observerOrNext === 'function') {
        // Duck-type an observer
        return {
            next: v => observerOrNext(v),
            error: e => onError && onError(e),
            complete: () => onComplete && onComplete(),
        };
    } else {
        // Make an object that calls to the given object, with safety checks
        return {
            next: v => observerOrNext.next && observerOrNext.next(v),
            error: e => observerOrNext.error && observerOrNext.error(e),
            complete: () => observerOrNext.complete && observerOrNext.complete(),
        };
    }
}

export default new PusherLink({
    pusher: pusher,
});
