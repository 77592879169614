import Pusher from 'pusher-js';

import {GraphQLAuthorizer} from './pusher-auth';

let client = null;

if (window.REALTIME !== undefined && window.REALTIME.ENABLED === true) {
    client = new Pusher(window.REALTIME.APPID, {
        wsHost: window.REALTIME.HOST || undefined,
        wsPort: window.REALTIME.WSPORT || undefined,
        wssPort: window.REALTIME.WSPORT || undefined,
        cluster: 'eu',
        forceTLS: true,
        authorizer: GraphQLAuthorizer,
        disableStats: true,
        enabledTransports: ['ws', 'wss'],
    });
}

export const pusher = client;
